import React from 'react';
import { Typography, Box, IconButton, Link } from '@mui/material';
import { Facebook, Instagram, Twitter, YouTube } from '@mui/icons-material';
import useStyles from './FooterStyles';
import { Link as RouterLink } from 'react-router-dom';

const Footer = () => {
  const classes = useStyles();
  const currentYear = new Date().getFullYear(); // Dynamically get the current year

  return (
    <footer className={classes.footer}>
      {/* Social Media Icons */}
      <Box className={classes.socialIcons}>
        {[
          { icon: <Facebook />, label: 'Facebook', url: 'https://facebook.com' },
          { icon: <Instagram />, label: 'Instagram', url: 'https://instagram.com' },
          { icon: <Twitter />, label: 'Twitter', url: 'https://twitter.com' },
          { icon: <YouTube />, label: 'YouTube', url: 'https://youtube.com' },
        ].map((item, index) => (
          <IconButton
            key={index}
            aria-label={item.label}
            className={classes.iconButton}
            component="a"
            href={item.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {item.icon}
          </IconButton>
        ))}
      </Box>

      {/* Footer Links */}
      <Box className={classes.footerLinks}>
        {[
          { text: 'Home', path: '/' },
          { text: 'About Us', path: '/about-us' },
          { text: 'Terms & Conditions', path: '/terms-and-conditions' },
        ].map((item, index) => (
          <Link
            key={index}
            component={RouterLink}
            to={item.path}
            className={classes.footerLink}
          >
            {item.text}
          </Link>
        ))}
      </Box>

      {/* Copyright Text */}
      <Typography variant="body2" className={classes.footerText}>
        © {currentYear} VICE CITY VIP. All rights reserved.
      </Typography>
    </footer>
  );
};

export default Footer;

