// HomePage.js


import React from 'react';
import { Box, Container, Typography, Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useStyles from './HomePageStyles';
import HeroSection from './HeroSection';
import Header from './Header.';
import Footer from '../footer/Footer';
import FeaturedCars from './FeaturedCars';

const HomePage = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate("/explore-cars");
  };

  return (
    <div className={classes.root}>
      <Header />

      <HeroSection />

      {/* Featured Cars Section */}
      <Box id="featured-cars" className={classes.featuredCarsSection}>
        <Container maxWidth="lg" style={{ padding: "50px 0" }}>
          <FeaturedCars />
        </Container>
      </Box>

      {/* "Why Choose Us?" Section for a better user experience */}
      <Box className={classes.whyChooseSection}>
        <Container maxWidth="lg">
          <Typography
            variant="h4"
            className={`${classes.neonText} ${classes.sectionTitle}`}
            gutterBottom
          >
            Why Choose Us?
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {/* Extensive Fleet */}
            <Grid item xs={12} md={4}>
              <Box className={classes.featureItem}>
                <img
                  src="/extensive.webp"
                  alt="Wide Selection"
                  className={classes.featureIcon}
                />
                <Typography variant="h6" className={classes.featureTitle}>
                  Extensive Fleet
                </Typography>
                <Typography className={classes.featureDescription}>
                  From Lamborghinis to Ferraris, access an unmatched selection
                  of exotic cars for any occasion.
                </Typography>
              </Box>
            </Grid>

            {/* Seamless Booking */}
            <Grid item xs={12} md={4}>
              <Box className={classes.featureItem}>
                <img
                  src="/booking.webp"
                  alt="Seamless Booking"
                  className={classes.featureIcon}
                />
                <Typography variant="h6" className={classes.featureTitle}>
                  Seamless Booking
                </Typography>
                <Typography className={classes.featureDescription}>
                  Browse and book your dream car effortlessly with a fast and
                  hassle-free rental process.
                </Typography>
              </Box>
            </Grid>

            {/* Luxury at the Best Price */}
            <Grid item xs={12} md={4}>
              <Box className={classes.featureItem}>
                <img
                  src="/deals.webp"
                  alt="Luxury for Less"
                  className={classes.featureIcon}
                />
                <Typography variant="h6" className={classes.featureTitle}>
                  Luxury at the Best Price
                </Typography>
                <Typography className={classes.featureDescription}>
                  Experience high-end exotic cars at competitive rates, ensuring
                  you get the best deal on luxury rentals.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Final Call to Action Section */}
      <Box
        style={{
          backgroundColor: "#000",
          padding: "50px 0",
          textAlign: "center",
        }}
      >
        <Typography variant="h4" className={classes.neonText}>
          Ready to Ride?
        </Typography>
        <Button
          className={classes.buttonNeon}
          size="large"
          style={{ marginTop: "20px" }}
          onClick={handleGetStarted}
        >
          Get Started
        </Button>
      </Box>

      <Footer />
    </div>
  );
};

export default HomePage;
