// AuthPage.jsx
import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete,
} from "@mui/material";
import useStyles from "./FormStyles";
import logo from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { states, cities } from "../data/statesAndCities"; // Import state & city data

const AuthPage = () => {
  const classes = useStyles();
  const [isLogin, setIsLogin] = useState(true);
  const [isBusiness, setIsBusiness] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    name: "",
    phone: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    businessName: "",
    businessPhone: "",
    businessCity: "",
    businessState: "",
    businessZip: "",
    businessCountry: "",
  });

  const { signup, login, currentUser, loading, authError } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      navigate("/dashboard");
    }
  }, [currentUser, navigate]);

  const handleFormSwitch = () => setIsLogin(!isLogin);
  const handleUserTypeChange = (event) =>
    setIsBusiness(event.target.value === "business");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleAuth = async (e) => {
    e.preventDefault();
    try {
      if (isLogin) {
        await login(formData.email, formData.password);
        navigate("/dashboard");
      } else {
        await signup(formData.email, formData.password, {
          userType: isBusiness ? "business" : "personal",
          name: formData.name,
          phone: formData.phone,
          address: {
            city: formData.city,
            state: formData.state,
            zip: formData.zip,
            country: formData.country,
          },
          businessAddress: isBusiness
            ? {
                businessName: formData.businessName,
                businessPhone: formData.businessPhone,
                businessCity: formData.businessCity,
                businessState: formData.businessState,
                businessZip: formData.businessZip,
                businessCountry: formData.businessCountry,
              }
            : null,
        });
        navigate("/dashboard");
      }
    } catch (error) {
      console.error("Authentication error:", error);
    }
  };

  return (
    <Container className={classes.container}>
      <Box className={classes.formBox}>
        <Box className={classes.logoContainer}>
          <img src={logo} alt="Vice City VIP" className={classes.logo} />
        </Box>

        <Typography variant="h4" className={classes.formTitle}>
          {isLogin ? "Login" : "Register"}
        </Typography>

        {!isLogin && (
          <RadioGroup
            row
            onChange={handleUserTypeChange}
            value={isBusiness ? "business" : "personal"}
            className={classes.radioGroup}
          >
            <FormControlLabel
              value="personal"
              control={<Radio />}
              label="Personal"
            />
            <FormControlLabel
              value="business"
              control={<Radio />}
              label="Business"
            />
          </RadioGroup>
        )}

        <form className={classes.form} onSubmit={handleAuth}>
          {!isLogin && (
            <>
              {isBusiness && (
                <>
                  <Typography variant="h6" className={classes.sectionTitle}>
                    Business Information
                  </Typography>
                  <TextField
                    name="businessName"
                    label="Business Name"
                    onChange={handleInputChange}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    className={classes.input}
                  />
                  <TextField
                    name="businessPhone"
                    label="Business Phone"
                    onChange={handleInputChange}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    className={classes.input}
                  />

                  {/* Business State & City with Autocomplete */}
                  <Autocomplete
                    options={states.map((s) => s.label)}
                    value={formData.businessState}
                    onChange={(event, newValue) =>
                      setFormData({
                        ...formData,
                        businessState: newValue,
                        businessCity: "",
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Business State"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        className={classes.dropdown}
                      />
                    )}
                  />
                  <Autocomplete
                    options={cities
                      .filter((c) => c.state === formData.businessState)
                      .map((c) => c.label)}
                    value={formData.businessCity}
                    onChange={(event, newValue) =>
                      setFormData({ ...formData, businessCity: newValue })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Business City"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        className={classes.dropdown}
                      />
                    )}
                  />

                  <TextField
                    name="businessZip"
                    label="Business Zip"
                    onChange={handleInputChange}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    className={classes.input}
                  />
                  <TextField
                    name="businessCountry"
                    label="Business Country"
                    onChange={handleInputChange}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    className={classes.input}
                  />
                </>
              )}

              <Typography variant="h6" className={classes.sectionTitle}>
                Personal Information
              </Typography>
              <TextField
                name="name"
                label="Name"
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                margin="normal"
                className={classes.input}
              />
              <TextField
                name="phone"
                label="Phone"
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                margin="normal"
                className={classes.input}
              />

              {/* Personal State & City with Autocomplete */}
              <Autocomplete
                options={states.map((s) => s.label)}
                value={formData.state}
                onChange={(event, newValue) =>
                  setFormData({ ...formData, state: newValue, city: "" })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="State"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    className={classes.dropdown}
                  />
                )}
              />

              <Autocomplete
                options={cities
                  .filter((c) => c.state === formData.state)
                  .map((c) => c.label)}
                value={formData.city}
                onChange={(event, newValue) =>
                  setFormData({ ...formData, city: newValue })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="City"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    className={classes.dropdown}
                  />
                )}
              />

              <TextField
                name="zip"
                label="Zip"
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                margin="normal"
                className={classes.input}
              />
              <TextField
                name="country"
                label="Country"
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                margin="normal"
                className={classes.input}
              />
            </>
          )}

          <Typography variant="h6" className={classes.sectionTitle}>
            Account Information
          </Typography>
          <TextField
            name="email"
            label="Email"
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
            margin="normal"
            className={classes.input}
          />
          <TextField
            name="password"
            label="Password"
            onChange={handleInputChange}
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            className={classes.input}
          />

          {authError && (
            <Typography color="error" className={classes.errorText}>
              {authError}
            </Typography>
          )}

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            className={classes.submitButton}
          >
            {isLogin ? "Login" : "Register"}
          </Button>
        </form>

        <Button
          color="secondary"
          className={classes.switchButton}
          onClick={handleFormSwitch}
        >
          {isLogin
            ? "Don't have an account? Register"
            : "Already have an account? Login"}
        </Button>
      </Box>
    </Container>
  );
};

export default AuthPage;
