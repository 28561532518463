import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  galleryContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '30px 0',
    backgroundColor: '#000',
  },
  mainImageContainer: {
    width: '100%',
    maxWidth: '900px',
    height: '500px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
    boxShadow: '0px 0px 20px #23d5ab',
  },
  mainImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '10px',
  },
  thumbnailContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
    marginTop: '15px',
    overflowX: 'auto',
    paddingBottom: '10px',
  },
  thumbnail: {
    width: '80px',
    height: '60px',
    objectFit: 'cover',
    cursor: 'pointer',
    borderRadius: '6px',
    transition: '0.3s ease',
    border: '2px solid transparent',
    boxShadow: '0px 0px 10px rgba(255, 255, 255, 0.3)',
    '&:hover': {
      border: '2px solid #f72585',
    },
  },
  activeThumbnail: {
    border: '2px solid #f72585',
    boxShadow: '0px 0px 15px #f72585',
  },

  // VEHICLE DETAILS SECTION
  detailsContainer: {
    padding: '50px 20px',
    backgroundColor: '#000',
    color: '#fff',
  },
  detailBox: {
    padding: '20px',
    textAlign: 'center',
    borderRadius: '10px',
    border: '2px solid #23d5ab',
    boxShadow: '0px 0px 10px #23d5ab',
    transition: '0.3s ease',
    '&:hover': {
      boxShadow: '0px 0px 20px #23d5ab',
    },
  },
  priceText: {
    color: '#23d5ab',
    fontWeight: 'bold',
  },
  descriptionBox: {
    marginTop: '40px',
    padding: '20px',
    borderRadius: '10px',
    border: '2px solid #f72585',
    boxShadow: '0px 0px 10px #f72585',
    textAlign: 'center',
  },

  // BUTTONS
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '40px',
  },
  goBackButton: {
    backgroundColor: '#23d5ab',
    color: '#fff',
    padding: '12px 30px',
    fontSize: '1.2rem',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: '#1cb993',
    },
  },

  // PAYMENT METHODS
  paymentMethodsBox: {
    marginTop: '30px',
    padding: '20px',
    borderRadius: '10px',
    border: '2px solid #f72585',
    boxShadow: '0px 0px 15px #f72585',
    textAlign: 'center',
    backgroundColor: 'rgba(255, 0, 100, 0.15)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paymentMethodsList: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '15px',
    marginBottom: '10px', // Space before the crypto logo
  },
  paymentMethod: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    padding: '8px 15px',
    borderRadius: '8px',
    backgroundColor: 'rgba(35, 213, 171, 0.2)',
    border: '2px solid #23d5ab',
    boxShadow: '0px 0px 10px #23d5ab',
    color: '#23d5ab',
    transition: '0.3s ease',
    '&:hover': {
      boxShadow: '0px 0px 20px #23d5ab',
    },
  },

  // VEHICLE INFO
  vehicleInfoContainer: {
    textAlign: 'center',
    padding: '40px 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  vehicleTitle: {
    fontSize: '4rem',
    fontWeight: 'bold',
    color: '#f72585',
    textShadow: '0px 0px 20px #f72585',
    marginBottom: '10px',
  },
  vehicleSubtitle: {
    fontSize: '1.5rem',
    color: '#23d5ab',
    marginBottom: '20px',
  },
  rentNowButton: {
    backgroundColor: '#f72585',
    color: '#fff',
    padding: '12px 30px',
    fontSize: '1.5rem',
    borderRadius: '8px',
    transition: '0.3s ease',
    boxShadow: '0px 0px 15px #f72585',
    '&:hover': {
      backgroundColor: '#d61c73',
    },
  },

 // CONTAINER THAT HOLDS THE COIN
 cryptoContainer: {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '2vh',
  perspective: '1000px', // Adjusted for better responsiveness
  '@media (max-width: 600px)': {
    perspective: '800px', // Reduce perspective depth on small screens
  },
},

// WRAPPER FOR THE COIN (ENSURES IT FLIPS CORRECTLY)
cryptoWrapper: {
  position: 'relative',
  width: '12vw', // Scales with screen width
  height: '12vw', // Maintain aspect ratio
  maxWidth: '150px', // Prevent it from getting too large
  maxHeight: '150px',
  transformStyle: 'preserve-3d',
  animation: '$coinFlip 2s infinite linear',

  '@media (max-width: 600px)': {
    width: '20vw', // Bigger size on small screens
    height: '20vw',
  },
},

// COIN FACE - FRONT
cryptoFaceFront: {
  position: 'absolute',
  width: '100%',
  height: '100%',
  borderRadius: '50%',
  background: 'url("/sol.svg") no-repeat center',
  backgroundSize: 'contain', // Better scaling on all screens
  transform: 'translateZ(5px)', // Push forward for visibility
},

// COIN FACE - BACK (Mirrors the front)
cryptoFaceBack: {
  position: 'absolute',
  width: '100%',
  height: '100%',
  borderRadius: '50%',
  background: 'url("/sol.svg") no-repeat center',
  backgroundSize: 'contain',
  transform: 'rotateY(180deg) translateZ(5px)', // Ensures proper flip visibility
},

// THICKNESS LAYERS TO MAKE IT 3D
cryptoLayer: {
  position: 'absolute',
  width: '100%',
  height: '100%',
  borderRadius: '50%',
  background: 'black', // Keeps the edge black
  transformStyle: 'preserve-3d',
},

'@keyframes coinFlip': {
  '0%': { transform: 'rotateY(0deg)' },
  '50%': { transform: 'rotateY(180deg)' },
  '100%': { transform: 'rotateY(360deg)' },
},
}));

export default useStyles;
