// src/components/explorecars/ExploreCarsPage.jsx

import React, { useState } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  Box,
  CardMedia,
  CircularProgress
} from '@mui/material';
import useStyles from './ExploreCarsPageStyles';
import useSearch from '../../hooks/useSearch';
import CarDetailsModal from '../modals/CarDetailsModal';
import ExploreCarsSearchSection from './ExploreCarsSearchSection';

const ExploreCarsPage = () => {
  // Local modal state management
  const [openModal, setOpenModal] = useState(false);
  const [selectedCar, setSelectedCar] = useState(null);
  const classes = useStyles();

  // Destructure search hook values and methods
  const {
    state,
    city,
    setCity,
    filteredCities,
    filteredCars,
    cars,
    loading,
    showAdvanced,
    setShowAdvanced,
    make,
    model,
    setModel,
    filteredModels,
    handleStateChange,
    handleMakeChange,
    handleSearch,
    carMakes,
    states = [],
    minPrice,
    setMinPrice,
    maxPrice,
    setMaxPrice,
  } = useSearch();

  const [hasSearched, setHasSearched] = useState(false);
  const carsToRender = filteredCars.length > 0 ? filteredCars : cars;

  const handleSearchClick = () => {
    handleSearch();
    setHasSearched(true);
  };

  // Open/close modal functions
  const handleOpenModal = (car) => {
    setSelectedCar(car);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedCar(null);
  };

  return (
    <Box className={classes.root}>
      <Container maxWidth="lg" className={classes.contentWrapper}>
        <Typography variant="h4" className={classes.neonText}>
          Explore Exotic Cars
        </Typography>

        {/* Search section imported from same directory */}
        <ExploreCarsSearchSection
          classes={classes}
          state={state}
          city={city}
          setCity={setCity}
          filteredCities={filteredCities}
          showAdvanced={showAdvanced}
          setShowAdvanced={setShowAdvanced}
          make={make}
          model={model}
          setModel={setModel}
          filteredModels={filteredModels}
          handleStateChange={handleStateChange}
          handleMakeChange={handleMakeChange}
          handleSearchClick={handleSearchClick}
          carMakes={carMakes}
          states={states}
          minPrice={minPrice}
          setMinPrice={setMinPrice}
          maxPrice={maxPrice}
          setMaxPrice={setMaxPrice}
        />

        {/* Loading indicator and results */}
        {loading ? (
          <Box textAlign="center" marginTop="40px">
            <CircularProgress color="inherit" className={classes.spinner} />
            <Typography variant="h6" className={classes.loadingText}>
              Searching cars...
            </Typography>
          </Box>
        ) : hasSearched && carsToRender.length > 0 ? (
          <Grid container spacing={4} className={classes.resultsContainer}>
            {carsToRender.map((car) => (
              <Grid item xs={12} sm={6} md={4} key={car.id}>
                <Paper
                  sx={{ backgroundColor: 'rgba(27, 27, 27, 0.7)' }}
                  className={classes.card}
                  onClick={() => handleOpenModal(car)}
                  elevation={3}
                >
                  <CardMedia
                    component="img"
                    className={classes.cardImage}
                    image={
                      car.imageUrls && car.imageUrls[0]
                        ? car.imageUrls[0]
                        : `${process.env.PUBLIC_URL}/logo.png`
                    }
                    alt={car.make || 'Car'}
                  />
                  <Typography variant="h6" className={classes.cardTitle}>
                    {car.make} {car.model}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.cardDescription}
                  >
                    {car.city || 'Unknown city'}, {car.state || 'Unknown state'}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.cardDescription}
                  >
                    {car.pricePerDay
                      ? `$${car.pricePerDay} / day`
                      : 'Price not available'}
                  </Typography>
                  <Button
                    className={classes.buttonNeon}
                    size="small"
                    style={{ marginTop: '10px' }}
                  >
                    Rent Now
                  </Button>
                </Paper>
              </Grid>
            ))}
          </Grid>
        ) : hasSearched ? (
          <Typography variant="h6" className={classes.noResultsText}>
            No cars found.
          </Typography>
        ) : null}
      </Container>

      {/* Car Details Modal */}
      {selectedCar && (
        <CarDetailsModal
          open={openModal}
          handleClose={handleCloseModal}
          selectedCar={selectedCar}
        />
      )}
    </Box>
  );
};

export default ExploreCarsPage;




// import React from 'react';
// import { Container, Grid, Paper, Typography, TextField, Button, Box, CardMedia, CircularProgress, Autocomplete } from '@mui/material';
// import useStyles from './ExploreCarsPageStyles'; // Updated styles import
// import useSearch from '../../hooks/useSearch';
// import CarDetailsModal from '../modals/CarDetailsModal';

// const ExploreCarsPage = () => {
//   const classes = useStyles();
//   const {
//     state,
//     setState,
//     city,
//     setCity,
//     filteredCities,
//     filteredCars,
//     cars,
//     loading,
//     showAdvanced,
//     setShowAdvanced,
//     make,
//     setMake,
//     model,
//     setModel,
//     filteredModels,
//     handleStateChange,
//     handleMakeChange,
//     handleSearch,
//     handleOpenModal,
//     handleClose,
//     openModal,
//     selectedCar,
//     carMakes,
//     states,
//     minPrice,
//     setMinPrice,
//     maxPrice,
//     setMaxPrice,
//   } = useSearch();

//   return (
//     <Box className={classes.root}>
//       <Container maxWidth="lg">
//         <Typography variant="h4" className={classes.neonText}>
//           Explore Exotic Cars
//         </Typography>

//         <Grid container spacing={3} className={classes.searchContainer}>
//           {/* State Autocomplete */}
//           <Grid item xs={12} sm={6}>
//             <Autocomplete
//               options={states}
//               getOptionLabel={(option) => option.label || ''}
//               value={state || null}
//               onChange={handleStateChange}
//               renderInput={(params) => <TextField {...params} label="State" variant="outlined" className={classes.inputField} />}
//             />
//           </Grid>

//           {/* City Autocomplete */}
//           <Grid item xs={12} sm={6}>
//             <Autocomplete
//               options={filteredCities}
//               getOptionLabel={(option) => option.label || ''}
//               value={city || null}
//               onChange={(event, value) => setCity(value)}
//               renderInput={(params) => <TextField {...params} label="City" variant="outlined" className={classes.inputField} />}
//             />
//           </Grid>

//           {/* Button to toggle advanced search */}
//           <Grid item xs={12} className={classes.searchButtonContainer}>
//             <Button className={classes.buttonNeon} onClick={() => setShowAdvanced(!showAdvanced)}>
//               {showAdvanced ? 'Hide Advanced Search' : 'Show Advanced Search'}
//             </Button>
//           </Grid>
//         </Grid>

//         {/* Advanced search fields for make, model, and price range */}
//         {showAdvanced && (
//           <Grid container spacing={3} className={classes.advancedSearchContainer}>
//             <Grid item xs={12} sm={4}>
//               <Autocomplete
//                 options={carMakes}
//                 getOptionLabel={(option) => option.label || ''}
//                 value={make || null}
//                 onChange={handleMakeChange}
//                 renderInput={(params) => <TextField {...params} label="Make" variant="outlined" className={classes.inputField} />}
//               />
//             </Grid>
//             <Grid item xs={12} sm={4}>
//               <Autocomplete
//                 options={filteredModels}
//                 getOptionLabel={(option) => option.label || ''}
//                 value={model || null}
//                 onChange={(event, value) => setModel(value)}
//                 renderInput={(params) => <TextField {...params} label="Model" variant="outlined" className={classes.inputField} />}
//                 disabled={!make}
//               />
//             </Grid>
//             {/* Price Range Fields */}
//             <Grid item xs={6} sm={2}>
//               <TextField
//                 label="Min Price ($)"
//                 variant="outlined"
//                 value={minPrice || ''}
//                 onChange={(e) => setMinPrice(e.target.value)}
//                 className={classes.inputField}
//               />
//             </Grid>
//             <Grid item xs={6} sm={2}>
//               <TextField
//                 label="Max Price ($)"
//                 variant="outlined"
//                 value={maxPrice || ''}
//                 onChange={(e) => setMaxPrice(e.target.value)}
//                 className={classes.inputField}
//               />
//             </Grid>
//           </Grid>
//         )}

//         {/* Search Button */}
//         <Grid item xs={12} className={classes.searchButtonContainer}>
//           <Button className={classes.buttonNeon} size="large" onClick={handleSearch}>
//             Search Cars
//           </Button>
//         </Grid>

//         {/* Loading and Results */}
//         {loading ? (
//           <Box textAlign="center" marginTop="40px">
//             <CircularProgress color="inherit" className={classes.spinner} />
//             <Typography variant="h6" className={classes.loadingText}>
//               Searching cars...
//             </Typography>
//           </Box>
//         ) : filteredCars.length > 0 ? (
//           <Grid container spacing={4} className={classes.resultsContainer}>
//             {filteredCars.map((car) => (
//               <Grid item xs={12} sm={6} md={4} key={car.id}>
//                 <Paper className={classes.card} onClick={() => handleOpenModal(car)}>
//                   <CardMedia component="img" height="140" image={car.image || `${process.env.PUBLIC_URL}/logo.png`} alt={car.name} />
//                   <Typography variant="h6" className={classes.cardTitle}>{car.name}</Typography>
//                   <Typography>{car.city}, {car.state}</Typography>
//                   <Typography>{car.price}</Typography>
//                   <Button className={classes.buttonNeon} size="small" style={{ marginTop: '10px' }}>
//                     Rent Now
//                   </Button>
//                 </Paper>
//               </Grid>
//             ))}
//           </Grid>
//         ) : (
//           <Typography variant="h6" className={classes.noResultsText}>
//             No cars found.
//           </Typography>
//         )}
//       </Container>

//       {/* Car Details Modal */}
//       {selectedCar && (
//         <CarDetailsModal
//           open={openModal}
//           handleClose={handleClose}
//           selectedCar={selectedCar}
//         />
//       )}
//     </Box>
//   );
// };

// export default ExploreCarsPage;
