// HowWeWorkScreen.js


import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Typography, Grid, Button, Collapse, Container } from '@mui/material';
import useStyles from './HowWeWorkStyles';
import { AiOutlineCar, AiOutlineUserAdd, AiOutlineDollarCircle, AiOutlineClockCircle, AiOutlineBulb } from 'react-icons/ai';

const HowWeWorkScreen = () => {
  const classes = useStyles();
  const [expandedSection, setExpandedSection] = useState(null);

  const toggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  return (
    <>
      <Helmet>
        <title>How We Work | Vice City VIP</title>
        <meta name="description" content="Discover how Vice City VIP connects luxury car owners with high-quality renters through AI-powered lead ranking and an exclusive lead marketplace." />
        <link rel="canonical" href="https://vicecityvip.com/how-we-work" />
      </Helmet>

      <Box className={classes.root}>

        {/* Hero Section */}
        <Box className={classes.heroSection}>
          <Typography variant="h3" className={classes.heroText}>
            The Future of Exotic Car Rentals
          </Typography>
          <Typography variant="h5" className={classes.subText}>
            AI-Driven Leads, Exclusive Access, and Smart Monetization
          </Typography>
        </Box>

        <Container>
          {/* Step-by-Step Process */}
          <Grid container spacing={6} className={classes.contentContainer}>

            {/* Step 1: Owners List Their Vehicles */}
            <Grid item xs={12} md={6}>
              <Box className={classes.section} onClick={() => toggleSection('list-vehicles')}>
                <AiOutlineCar className={classes.icon} />
                <Typography variant="h5" className={classes.sectionTitle}>
                  Step 1: List Your Exotic Car
                </Typography>
                <Collapse in={expandedSection === 'list-vehicles'}>
                  <Typography variant="body1" className={classes.sectionText}>
                    Owners and rental agencies can **list their luxury vehicles** on Vice City VIP, showcasing availability, pricing, and car details.
                  </Typography>
                  <Typography variant="body2" className={classes.sectionSubText}>
                    Your listing gets **instant exposure** to verified renters looking for high-end vehicles.
                  </Typography>
                </Collapse>
              </Box>
            </Grid>

            {/* Step 2: AI-Driven Lead Ranking */}
            <Grid item xs={12} md={6}>
              <Box className={classes.section} onClick={() => toggleSection('lead-ranking')}>
                <AiOutlineBulb className={classes.icon} />
                <Typography variant="h5" className={classes.sectionTitle}>
                  Step 2: AI-Powered Lead Ranking
                </Typography>
                <Collapse in={expandedSection === 'lead-ranking'}>
                  <Typography variant="body1" className={classes.sectionText}>
                    Our AI system **analyzes, ranks, and scores leads** based on **criminal risk, financial background, and behavior patterns**.
                  </Typography>
                  <Typography variant="body2" className={classes.sectionSubText}>
                    This ensures **only high-quality renters** get access to premium vehicles.
                  </Typography>
                </Collapse>
              </Box>
            </Grid>

            {/* Step 3: Owners Get Exclusive Access to Leads */}
            <Grid item xs={12} md={6}>
              <Box className={classes.section} onClick={() => toggleSection('exclusive-leads')}>
                <AiOutlineClockCircle className={classes.icon} />
                <Typography variant="h5" className={classes.sectionTitle}>
                  Step 3: 3-Day Exclusive Lead Access
                </Typography>
                <Collapse in={expandedSection === 'exclusive-leads'}>
                  <Typography variant="body1" className={classes.sectionText}>
                    Owners receive **3 days of exclusive access** to leads generated from their listings. These leads are **free or available at a heavily discounted rate**.
                  </Typography>
                  <Typography variant="body2" className={classes.sectionSubText}>
                    If you claim the lead within this window, **you lock in a direct rental deal at no extra cost**.
                  </Typography>
                </Collapse>
              </Box>
            </Grid>

            {/* Step 4: AI-Priced Lead Marketplace */}
            <Grid item xs={12} md={6}>
              <Box className={classes.section} onClick={() => toggleSection('lead-marketplace')}>
                <AiOutlineDollarCircle className={classes.icon} />
                <Typography variant="h5" className={classes.sectionTitle}>
                  Step 4: Leads Enter the Open Marketplace
                </Typography>
                <Collapse in={expandedSection === 'lead-marketplace'}>
                  <Typography variant="body1" className={classes.sectionText}>
                    After 3 days, **unclaimed leads** enter the **Vice City VIP Marketplace**, where they can be **purchased at AI-determined rates** based on demand and client risk scores.
                  </Typography>
                  <Typography variant="body2" className={classes.sectionSubText}>
                    Secure **high-value leads** that match your rental criteria, ensuring **maximum profits**.
                  </Typography>
                </Collapse>
              </Box>
            </Grid>

          </Grid>

          {/* Call to Action */}
          <Box className={classes.ctaSection}>
            <Button className={classes.ctaButton}>
              Start Listing Your Vehicles Today
            </Button>
          </Box>

        </Container>
      </Box>
    </>
  );
};

export default HowWeWorkScreen;
