// CreateListing.js



// CreateListing.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Autocomplete,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  IconButton,
  Tooltip
} from '@mui/material';
import {
  CloudUpload as CloudUploadIcon,
  Delete as DeleteIcon,
  Star as StarIcon,
  StarOutline as StarOutlineIcon
} from '@mui/icons-material';
import useStyles from './CreateListingStyles';
import carsData from './../data/cars.json';
import useCreateListing from '../../hooks/useCreateListing';

const paymentOptions = ['Cash', 'Credit Card', 'PayPal', 'Bank Transfer', 'Crypto'];

const CreateListing = () => {
  const classes = useStyles();

  // State variables for listing details
  const [selectedMake, setSelectedMake] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [models, setModels] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]); // Array of { file, preview }
  const [carPrice, setCarPrice] = useState("");
  const [deliveryOffered, setDeliveryOffered] = useState(false);
  const [description, setDescription] = useState("");
  const [cryptoAccepted, setCryptoAccepted] = useState(false);
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState([]);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const { createListing, loading, error } = useCreateListing();

  // Update available models when a make is selected.
  useEffect(() => {
    const carMake = carsData.makes.find((make) => make.make === selectedMake);
    setModels(carMake ? carMake.models : []);
  }, [selectedMake]);

  // Debug: log selected images changes.
  useEffect(() => {
    console.log("Updated Selected Images:", selectedImages);
  }, [selectedImages]);

  // Toggle payment methods.
  const handlePaymentMethodChange = (method) => {
    setSelectedPaymentMethods((prevMethods) =>
      prevMethods.includes(method)
        ? prevMethods.filter((item) => item !== method)
        : [...prevMethods, method]
    );
  };

  // Submit listing data with images.
  const handleSubmit = async () => {
    if (!selectedMake || !selectedModel || !carPrice) {
      alert("Please fill in all required fields.");
      return;
    }

    const listingData = {
      make: selectedMake,
      model: selectedModel,
      pricePerDay: carPrice,
      deliveryOffered,
      description,
      cryptoAccepted,
      paymentMethods: selectedPaymentMethods,
    };

    // The first image in the array (index 0) is considered the main gallery image.
    const success = await createListing(
      listingData,
      selectedImages.map(img => img.file)
    );
    if (success) {
      setOpenSuccessModal(true);
    }
  };

  // Handle image uploads ensuring a maximum of 3 images.
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length === 0) return;
    
    const currentCount = selectedImages.length;
    const availableSlots = 3 - currentCount;
    
    if (availableSlots <= 0) {
      alert("You already have 3 images. Please remove one before adding new images.");
      return;
    }
    
    const newImages = files.slice(0, availableSlots).map(file => ({
      file,
      preview: URL.createObjectURL(file)
    }));
  
    setSelectedImages(prevImages => [...prevImages, ...newImages]);
  };

  // Delete image and revoke its URL.
  const handleDeleteImage = (index) => {
    const imageToDelete = selectedImages[index];
    URL.revokeObjectURL(imageToDelete.preview);
    const newImages = [...selectedImages];
    newImages.splice(index, 1);
    setSelectedImages(newImages);
  };

  // Set a new main image by moving the selected image to index 0.
  const handleSetMainImage = (index) => {
    if (index === 0) return; // Already the main image.
    
    setSelectedImages((prevImages) => {
      const newImages = [...prevImages];
      const [selectedImage] = newImages.splice(index, 1);
      return [selectedImage, ...newImages];
    });
  };

  // Format price input.
  const handlePriceChange = (e) => {
    setCarPrice(e.target.value);
  };

  const handlePriceBlur = () => {
    const numericValue = carPrice.replace(/\D/g, "");
    setCarPrice(numericValue ? numericValue : "");
  };

  // Reset the form on modal close.
  const handleModalClose = () => {
    setOpenSuccessModal(false);
    setSelectedMake("");
    setSelectedModel("");
    setModels([]);
    // Revoke preview URLs on reset.
    selectedImages.forEach(img => URL.revokeObjectURL(img.preview));
    setSelectedImages([]);
    setCarPrice("");
    setDeliveryOffered(false);
    setDescription("");
    setCryptoAccepted(false);
    setSelectedPaymentMethods([]);
  };

  return (
    <Box className={classes.container}>
      <Typography variant="h4" className={classes.neonText}>
        Create a New Listing
      </Typography>

      <form className={classes.form}>
        <Autocomplete
          options={carsData.makes.map((make) => make.make)}
          value={selectedMake}
          onChange={(event, newValue) => setSelectedMake(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Car Make"
              variant="outlined"
              fullWidth
              className={classes.textField}
              InputLabelProps={{ className: classes.label }}
            />
          )}
        />

        <Autocomplete
          options={models}
          value={selectedModel}
          onChange={(event, newValue) => setSelectedModel(newValue)}
          disabled={!selectedMake}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Car Model"
              variant="outlined"
              fullWidth
              className={classes.textField}
              InputLabelProps={{ className: classes.label }}
            />
          )}
        />

        <TextField
          label="Car Price per Day"
          variant="outlined"
          fullWidth
          value={carPrice}
          onChange={handlePriceChange}
          onBlur={handlePriceBlur}
          className={classes.textField}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            endAdornment: <InputAdornment position="end">/day</InputAdornment>,
            inputMode: "numeric",
            className: classes.inputText,
          }}
          InputLabelProps={{ className: classes.label }}
        />

        <TextField
          label="Description"
          variant="outlined"
          multiline
          rows={4}
          fullWidth
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className={classes.textField}
          InputProps={{ className: classes.inputText }}
          InputLabelProps={{ className: classes.label }}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={deliveryOffered}
              onChange={(e) => setDeliveryOffered(e.target.checked)}
              color="primary"
              className={classes.checkbox}
            />
          }
          label="Delivery Offered"
          className={classes.checkboxLabel}
        />

        <Typography variant="h6" className={classes.sectionTitle}>
          Payment Options
        </Typography>

        <FormControlLabel
          control={
            <Checkbox
              checked={cryptoAccepted}
              onChange={(e) => setCryptoAccepted(e.target.checked)}
              color="primary"
              className={classes.checkbox}
            />
          }
          label="Enable Crypto Listing"
          className={classes.checkboxLabel}
        />

        {paymentOptions.map((method) => (
          <FormControlLabel
            key={method}
            control={
              <Checkbox
                checked={selectedPaymentMethods.includes(method)}
                onChange={() => handlePaymentMethodChange(method)}
                color="primary"
                className={classes.checkbox}
              />
            }
            label={method}
            className={classes.checkboxLabel}
          />
        ))}

        <Box className={classes.uploadContainer}>
          <input
            accept="image/*"
            className={classes.fileInput}
            id="upload-button-file"
            type="file"
            multiple
            onChange={handleImageChange}
          />
          <label htmlFor="upload-button-file">
            <Button
              variant="contained"
              color="primary"
              component="span"
              className={classes.uploadButton}
              startIcon={<CloudUploadIcon />}
            >
              {selectedImages.length > 0
                ? `${selectedImages.length} Image${selectedImages.length > 1 ? "s" : ""} Uploaded`
                : "Upload Images (Max 3)"}
            </Button>
          </label>
        </Box>

        {/* Image Preview Section */}
        {selectedImages.length > 0 && (
          <Box className={classes.imagePreviewContainer}>
            {selectedImages.map((img, index) => (
              <Box key={index} className={classes.imagePreviewBox} position="relative">
                <img 
                  src={img.preview} 
                  alt={`Upload Preview ${index}`} 
                  className={classes.imagePreview} 
                />
                {/* If this is the main image (index 0), show an overlay badge */}
                {index === 0 && (
                  <Box className={classes.mainBadge}>
                    <Typography variant="caption" color="secondary">Main</Typography>
                  </Box>
                )}
                <Box className={classes.previewActions}>
                  {/* Delete Button */}
                  <IconButton onClick={() => handleDeleteImage(index)} color="error" size="small">
                    <DeleteIcon />
                  </IconButton>
                  {/* Star Button to set as main image */}
                  <Tooltip title={index === 0 ? "Main Image" : "Set as Main Image"}>
                    {/* Wrapping IconButton in a span so tooltip works even when disabled */}
                    <span>
                      <IconButton
                        onClick={() => handleSetMainImage(index)}
                        color={index === 0 ? "secondary" : "primary"}
                        size="small"
                        disabled={index === 0}
                      >
                        {index === 0 ? <StarIcon /> : <StarOutlineIcon />}
                      </IconButton>
                    </span>
                  </Tooltip>
                </Box>
              </Box>
            ))}
          </Box>
        )}

        <Button
          variant="contained"
          onClick={handleSubmit}
          className={`${classes.submitButton} ${loading ? classes.pulsatingButton : ""}`}
          disabled={loading}
          startIcon={
            loading ? (
              <CircularProgress size={24} sx={{ color: "#fff" }} />
            ) : null
          }
        >
          {loading ? "Submitting..." : "Submit Listing"}
        </Button>

        {error && <Typography color="error">{error}</Typography>}
      </form>

      <Dialog
        open={openSuccessModal}
        onClose={handleModalClose}
        classes={{ paper: classes.successModal }}
      >
        <DialogTitle className={classes.modalTitle}>
          Listing Created Successfully!
        </DialogTitle>
        <DialogContent>
          <Typography className={classes.modalText}>
            Your vehicle listing for {selectedMake} {selectedModel} has been successfully created.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} className={classes.modalButton}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CreateListing;
