// AboutUsScreen.js


import React from 'react';
import { Box, Typography, Grid, Button, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useStyles from './AboutUsStyles';

const AboutUsScreen = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleNavigateToHowWeWork = () => {
    navigate('/how-we-work');
  };

  return (
    <Box className={classes.root}>
      {/* Hero Section */}
      <Box className={classes.heroSection}>
        <Typography variant="h3" className={classes.heroText}>
          Redefining Luxury Rentals with AI
        </Typography>
        <Typography variant="h5" className={classes.subText}>
          Experience the future of exotic car rentals with Vice City VIP
        </Typography>
      </Box>

      <Container>
        <Grid container spacing={6} className={classes.contentContainer}>
          
          {/* Who We Are */}
          <Grid item xs={12} md={6}>
            <Box className={classes.section}>
              <Typography variant="h5" className={classes.sectionTitle}>
                Who We Are
              </Typography>
              <Typography variant="body1" className={classes.sectionText}>
                Vice City VIP is the premier **exotic car rental marketplace**, seamlessly linking vehicle owners and agencies with elite clientele. 
                We are not just a platform; we are a **curated luxury experience** driven by **AI-powered risk assessment**, ensuring a **secure and premium** rental process.
              </Typography>
            </Box>
          </Grid>

          {/* How We Operate */}
          <Grid item xs={12} md={6}>
            <Box className={classes.section}>
              <Typography variant="h5" className={classes.sectionTitle}>
                How We Operate
              </Typography>
              <Typography variant="body1" className={classes.sectionText}>
                We offer a **streamlined, AI-enhanced** rental process, connecting you to the world’s most **exclusive exotic vehicles** while maintaining **optimal security** for owners. 
                Our **proprietary ranking system** ensures only **verified, high-quality leads** make it through.
              </Typography>
            </Box>
          </Grid>

          {/* AI-Driven Security & Lead Analysis */}
          <Grid item xs={12} md={6}>
            <Box className={classes.section}>
              <Typography variant="h5" className={classes.sectionTitle}>
                AI-Powered Risk Assessment
              </Typography>
              <Typography variant="body1" className={classes.sectionText}>
                Our **AI-driven fraud prevention** technology **analyzes and ranks** every lead based on **background checks, financial credibility, client risk levels, and behavioral patterns**. 
                This means **rental agencies and owners can rent with confidence**, minimizing risk and maximizing profitability.
              </Typography>
            </Box>
          </Grid>

          {/* Redefining Luxury & Security */}
          <Grid item xs={12} md={6}>
            <Box className={classes.section}>
              <Typography variant="h5" className={classes.sectionTitle}>
                Redefining Luxury with Security
              </Typography>
              <Typography variant="body1" className={classes.sectionText}>
                Security and exclusivity go hand in hand. Our advanced AI **filters out high-risk individuals**, 
                ensuring that **every transaction is seamless and secure**. With Vice City VIP, **you’re always in control**.
              </Typography>
            </Box>
          </Grid>

        </Grid>

        {/* Call to Action */}
        <Box className={classes.ctaSection}>
          <Button className={classes.ctaButton} onClick={handleNavigateToHowWeWork}>
            Discover How We Work
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default AboutUsScreen;

