// App.jsx

import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { AuthProvider } from './hooks/useAuth';
import { CartProvider } from './context/CartContext';
import PWAInstallButton from './utils/PWAInstallButton';

import AppRoutes from './routes/routes'; // Import the routes file

const theme = createTheme({
  palette: {
    primary: { main: '#23d5ab' },
    secondary: { main: '#f72585' },
    background: { default: '#000' },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
});

const App = () => {
  return (
    <AuthProvider>
      <CartProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <AppRoutes />
          </Router>
          <PWAInstallButton />
        </ThemeProvider>
      </CartProvider>
    </AuthProvider>
  );
};

export default App;
